@charset "UTF-8";
/*!
 * Bootstrap Grid v4.1.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@-ms-viewport {
  width: device-width; }

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

/*@font-face {
    font-family: 'intro';
    src: url('../fonts/intro.eot');
    src: url('../fonts/intro.eot') format('embedded-opentype'),
         url('../fonts/intro.woff2') format('woff2'),
         url('../fonts/intro.woff') format('woff'),
         url('../fonts/intro.ttf') format('truetype'),
         url('../fonts/intro.svg#8289') format('svg');
}*/
@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat-Regular/Montserrat-Regular.eot");
  src: url("../fonts/Montserrat-Regular/Montserrat-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Regular/Montserrat-Regular.woff2") format("woff2"), url("../fonts/Montserrat-Regular/Montserrat-Regular.woff") format("woff"), url("../fonts/Montserrat-Regular/Montserrat-Regular.ttf") format("truetype"), url("../fonts/Montserrat-Regular/Montserrat-Regular.svg#Montserrat-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat-Bold/Montserrat-Bold.eot");
  src: url("../fonts/Montserrat-Bold/Montserrat-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Bold/Montserrat-Bold.woff2") format("woff2"), url("../fonts/Montserrat-Bold/Montserrat-Bold.woff") format("woff"), url("../fonts/Montserrat-Bold/Montserrat-Bold.ttf") format("truetype"), url("../fonts/Montserrat-Bold/Montserrat-Bold.svg#Montserrat-Bold") format("svg");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat/Montserrat-Light/Montserrat-Light.eot");
  src: url("../fonts/Montserrat/Montserrat-Light/Montserrat-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat/Montserrat-Light/Montserrat-Light.woff2") format("woff2"), url("../fonts/Montserrat/Montserrat-Light/Montserrat-Light.woff") format("woff"), url("../fonts/Montserrat/Montserrat-Light/Montserrat-Light.ttf") format("truetype"), url("../fonts/Montserrat/Montserrat-Light/Montserrat-Light.svg#Montserrat-Light") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat/Montserrat-Italic/Montserrat-Italic.eot");
  src: url("../fonts/Montserrat/Montserrat-Italic/Montserrat-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat/Montserrat-Italic/Montserrat-Italic.woff2") format("woff2"), url("../fonts/Montserrat/Montserrat-Italic/Montserrat-Italic.woff") format("woff"), url("../fonts/Montserrat/Montserrat-Italic/Montserrat-Italic.ttf") format("truetype"), url("../fonts/Montserrat/Montserrat-Italic/Montserrat-Italic.svg#Montserrat-Italic") format("svg");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat-Medium-Italic/Montserrat-MediumItalic.eot");
  src: url("../fonts/Montserrat-Medium-Italic/Montserrat-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Medium-Italic/Montserrat-MediumItalic.woff2") format("woff2"), url("../fonts/Montserrat-Medium-Italic/Montserrat-MediumItalic.woff") format("woff"), url("../fonts/Montserrat-Medium-Italic/Montserrat-MediumItalic.ttf") format("truetype"), url("../fonts/Montserrat-Medium-Italic/Montserrat-MediumItalic.svg#Montserrat-MediumItalic") format("svg");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: 'HelveticaNeue';
  src: url("../fonts/Helvetica-Neue-Regular/HelveticaNeueRegular.eot");
  src: url("../fonts/Helvetica-Neue-Regular/HelveticaNeueRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/Helvetica-Neue-Regular/HelveticaNeueRegular.woff2") format("woff2"), url("../fonts/Helvetica-Neue-Regular/HelveticaNeueRegular.woff") format("woff"), url("../fonts/Helvetica-Neue-Regular/HelveticaNeueRegular.ttf") format("truetype"), url("../fonts/Helvetica-Neue-Regular/HelveticaNeueRegular.svg#HelveticaNeueRegular") format("svg");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'Source Sans Pro';
  src: url("../fonts/Source-Sans-Pro/SourceSansPro-Regular.eot");
  src: url("../fonts/Source-Sans-Pro/SourceSansPro-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Source-Sans-Pro/SourceSansPro-Regular.woff2") format("woff2"), url("../fonts/Source-Sans-Pro/SourceSansPro-Regular.woff") format("woff"), url("../fonts/Source-Sans-Pro/SourceSansPro-Regular.ttf") format("truetype"), url("../fonts/Source-Sans-Pro/SourceSansPro-Regular.svg#SourceSansPro-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'GothamPro';
  src: url(../fonts/GothamPro.eot?#iefix) format("embedded-opentype"), url(../fonts/GothamPro.woff) format("woff"), url(../fonts/GothamPro.ttf) format("truetype"), url(../fonts/GothamPro.svg#GothamPro) format("svg");
  font-weight: normal;
  font-style: normal; }

/* http://okna-spb.ru/ */
/*
@font-face {
  font-family: 'GothamMediumItalic';
  src:  url(//okna-spb.ru/wp-content/themes/shablon/fonts/GothamMediumItalic.eot?#iefix) format('embedded-opentype'),
        url(//okna-spb.ru/wp-content/themes/shablon/fonts/GothamMediumItalic.woff) format('woff'),
        url(//okna-spb.ru/wp-content/themes/shablon/fonts/GothamMediumItalic.ttf) format('truetype'),
        url(//okna-spb.ru/wp-content/themes/shablon/fonts/GothamMediumItalic.svg#GothamMediumItalic) format('svg');
  font-weight: normal;
  font-style: normal;
}
*/
@font-face {
  font-family: 'GothamProBlack';
  src: url(../fonts/GothamProBlack.eot?#iefix) format("embedded-opentype"), url(../fonts/GothamProBlack.woff) format("woff"), url(../fonts/GothamProBlack.ttf) format("truetype"), url(../fonts/GothamProBlack.svg#GothamProBlack) format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'GothamProBold';
  src: url(../fonts/GothamProBold.eot?#iefix) format("embedded-opentype"), url(../fonts/GothamProBold.otf) format("opentype"), url(../fonts/GothamProBold.woff) format("woff"), url(../fonts/GothamProBold.ttf) format("truetype"), url(../fonts/GothamProBold.svg#GothamProBold) format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'GothamProReg';
  src: url(../fonts/GothamProReg.eot?#iefix) format("embedded-opentype"), url(../fonts/GothamProReg.otf) format("opentype"), url(../fonts/GothamProReg.woff) format("woff"), url(../fonts/GothamProReg.ttf) format("truetype"), url(../fonts/GothamProReg.svg#GothamProReg) format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'GothamProLight';
  src: url(../fonts/GothamProLight.eot?#iefix) format("embedded-opentype"), url(../fonts/GothamProLight.otf) format("opentype"), url(../fonts/GothamProLight.woff) format("woff"), url(../fonts/GothamProLight.ttf) format("truetype"), url(../fonts/GothamProLight.svg#GothamProLight) format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'GothamProMedium';
  src: url(../fonts/GothamProMedium.eot?#iefix) format("embedded-opentype"), url(../fonts/GothamProMedium.otf) format("opentype"), url(../fonts/GothamProMedium.woff) format("woff"), url(../fonts/GothamProMedium.ttf) format("truetype"), url(../fonts/GothamProMedium.svg#GothamProMedium) format("svg");
  font-weight: normal;
  font-style: normal; }

body {
  font-family: 'GothamProReg';
  color: #111;
  font-weight: 300;
  line-height: 1.2;
  min-height: 100vh; }

b, strong {
  font-weight: bold; }

.flex-grow {
  flex: 1; }

.b-header {
  background: #1fc2f0;
  color: #fff; }

.b-header__logo-entity {
  display: inline-block;
  font-size: 30px;
  margin-top: 24px;
  text-shadow: 3px 3px 1px #000; }

a .b-header__logo-entity {
  color: #fff; }

.b-header__buttons {
  padding-top: 24px;
  text-align: center; }

.b-header__phones {
  padding-top: 14px; }

.b-header__phones-list {
  font-size: 18px;
  line-height: 32px;
  text-align: right; }

.b-header__phones-item {
  display: block; }

.b-header .col {
  min-width: 250px; }

@media screen and (max-width: 750px) {
  .b-header__buttons {
    text-align: right; }
  .b-header__phones-list {
    display: flex;
    justify-content: space-between; } }

@media screen and (max-width: 510px) {
  .b-header .col {
    min-width: auto; }
  .b-header__phones-list {
    flex-direction: column;
    text-align: center; } }

@media screen and (max-width: 430px) {
  .b-header .col {
    min-width: 100%; }
  .b-header__logo {
    text-align: center; }
  .b-header__buttons {
    text-align: center; } }

.b-menu {
  background: #f8f8f8; }

.b-menu ul {
  color: #1a3945;
  font-size: 14px;
  border-bottom: 2px solid #f8f8f8;
  transition: border-color .2s;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 12px; }

.b-menu ul a {
  color: #1a3945;
  padding: 12px 10px 10px;
  text-decoration: none;
  display: inline-block; }

.b-menu ul a.active,
.b-menu ul a:hover {
  color: #20c2f0;
  transition: color .2s; }

.b-service {
  margin-top: 32px; }

@media screen and (max-width: 700px) {
  .b-service {
    font-size: 14px; }
  .b-service .row {
    display: block; }
  .b-service .fa {
    font-size: 32px; } }

.b-manifactures {
  margin-top: 32px;
  padding-top: 32px; }

@media screen and (max-width: 800px) {
  .b-manifactures .col {
    text-align: center; } }

.b-price-reasons__container {
  background: #fecd06; }

.b-price-reasons {
  margin-top: 32px;
  padding-top: 32px; }

.b-price-reasons h2 {
  margin-bottom: 22px; }

.b-price-reasons .card {
  margin: 0 2% 30px 0;
  width: 48% !important; }

.b-price-reasons .card-title {
  border-bottom: 3px solid #fecd06;
  vertical-align: middle; }

@media screen and (max-width: 800px) {
  .b-price-reasons .card {
    width: 100% !important; } }

.b-order-reasons {
  margin-top: 32px;
  padding-top: 32px; }

.b-order-reasons h2 {
  margin-bottom: 20px; }

.b-order-reasons .card {
  border: none;
  text-align: center; }

.b-order-reasons .card-img-top {
  margin: 0 auto;
  width: auto; }

@media screen and (max-width: 800px) {
  .b-order-reasons .card {
    width: 100% !important; } }

.b-calc {
  margin-top: 32px;
  padding-top: 32px; }

.b-calc #firm_div img {
  cursor: pointer; }

@media screen and (max-width: 650px) {
  .b-calc #firm_div img {
    min-width: 150px;
    max-width: 150px;
    margin-bottom: 12px; } }

.b-calc__window-active {
  border: 2px solid #c6cc2e;
  background: #fda913;
  box-shadow: 2px 5px 5px #bbb; }

@media screen and (max-width: 800px) {
  .b-calc {
    justify-content: center; }
  .b-calc__options {
    flex-direction: column; }
  .b-calc__options .col {
    min-height: auto; }
  .b-calc__preview {
    display: none; }
  .b-calc__preview-options {
    min-width: 100%;
    width: 100%; } }

.b-prices__container {
  background: linear-gradient(45deg, #177396, #0cc1b3);
  padding-top: 90px;
  padding-bottom: 90px; }

.b-prices__sum {
  color: #ff6666;
  font-size: 26px; }

.b-prices .card {
  margin-bottom: 14px; }

.b-prices .card-title {
  font-size: 18px; }

.b-prices .card-body ul {
  font-size: 14px;
  list-style: none;
  padding-left: 0; }

.b-prices .card-body li {
  background: url("../img/red-marks.png") no-repeat;
  background-position-y: 5px;
  font-size: 14px;
  line-height: 24px;
  padding-left: 20px; }

.b-order {
  background-image: url("../img/zamer_bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  margin-top: 32px;
  margin-top: 0;
  padding-top: 32px; }

.b-order h2 {
  color: white;
  font-size: 38px;
  font-weight: bold;
  text-align: center; }

.b-order .form-control {
  color: white;
  background: none;
  border: 1px solid white;
  border-radius: 15px;
  height: 54px;
  width: 192px;
  padding: 0 20px; }

.b-order input:focus {
  box-shadow: none;
  outline: none; }

.b-order .form-control::placeholder {
  color: #bbb; }

.b-order .btn {
  background: white;
  border: none;
  border-radius: 15px;
  color: #315682;
  display: block;
  font-size: 18px;
  font-weight: bold;
  padding: 15px;
  text-align: center; }

.b-order .btn:hover {
  color: #ff6666; }

.b-order .form-check {
  text-align: center;
  width: 100%; }

.b-order .form-check label {
  color: white;
  font-size: 12px; }

.b-order .form-check a {
  color: white;
  text-decoration: underline; }

.b-order .form-check a:hover {
  color: #ff6666;
  text-decoration: none; }

@media screen and (max-width: 800px) {
  .b-order .form-control {
    width: 100%; }
  .b-order .btn-primary {
    width: 85% !important; } }

.b-address__title {
  height: 0; }

.b-address__title h2 {
  padding-top: 12px;
  position: absolute; }

.b-content {
  margin-top: 12px;
  padding-top: 12px; }

.b-footer {
  background: #222;
  color: #fff;
  font-size: 14px;
  padding-bottom: 42px;
  padding-top: 42px; }

.b-footer a {
  color: #1fc2f0; }

/* Additional pages */
.b-balkony {
  background-image: url("../img/eurookna_bg.jpg");
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  color: #fff;
  padding-top: 70px;
  padding-bottom: 70px; }

.b-balkony .col {
  width: calc(100% * (1 / 3.001));
  flex-basis: auto; }

@media screen and (max-width: 950px) {
  .b-balkony .col {
    width: 50%; } }

@media screen and (max-width: 750px) {
  .b-balkony .col {
    width: 100%; } }

.b-balkony__item {
  color: #fff;
  display: flex;
  fill: currentcolor;
  flex-wrap: wrap;
  align-items: center;
  text-transform: uppercase;
  margin-top: 70px;
  padding-left: 50px; }

.b-balkony__item p {
  padding-left: 30px;
  padding-top: 15px; }

.b-balkony-service {
  margin-top: 32px; }

/* общие стили
====================================================*/
html,
body {
  font-size: 14px; }

body {
  position: relative;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 1.3;
  color: #000000;
  background: #fff; }

.container {
  max-width: 1430px; }
  .container-main {
    margin: 0 auto;
    padding: 0 10px;
    max-width: 1600px; }

img {
  max-width: 100%;
  height: auto; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-style: normal; }

h1 {
  font-size: 46px; }

h2 {
  font-size: 38px; }
  @media screen and (max-width: 400px) {
    h2 {
      font-size: 32px; } }

h3 {
  font-size: 32px; }

h4 {
  font-size: 28px; }

h5 {
  font-size: 24px; }

h6 {
  font-size: 18px; }

.article h2 {
  font-size: 46px; }
  @media screen and (max-width: 460px) {
    .article h2 {
      font-size: 32px; } }

a {
  text-decoration: none; }
  a:hover, a:focus {
    text-decoration: none;
    cursor: pointer; }

p {
  margin: 0;
  padding: 0; }

.b-form {
  /*b-form__title
  =======================================*/
  /* form-group
  ====================================================*/
  /* agree
    ====================================================*/ }
  .b-form__title {
    margin-bottom: 30px; }
    @media screen and (max-width: 1180px) {
      .b-form__title {
        text-align: center; } }
  .b-form .b-form-group {
    display: inline-block;
    vertical-align: top;
    margin-right: 8px;
    margin-bottom: 11px;
    width: 253px;
    /*b-form-group__label
    =======================================*/ }
    .b-form .b-form-group__label {
      display: block;
      margin-bottom: 8px;
      font-size: 18px;
      font-weight: bold; }
    .b-form .b-form-group input[type="text"],
    .b-form .b-form-group input[type="email"] {
      width: 100%;
      height: 46px;
      font-size: 14px;
      text-indent: 20px;
      border-radius: 6px;
      border: 1px solid #d0d0d0;
      background-color: #ffffff; }
      .b-form .b-form-group input[type="text"]:focus,
      .b-form .b-form-group input[type="email"]:focus {
        box-shadow: 0 0 8px 1px rgba(0, 3, 1, 0.2);
        border-radius: 6px;
        border: none;
        background-color: #ffffff; }
  .b-form .b-agree {
    margin-bottom: 30px; }
    .b-form .b-agree__link {
      color: inherit; }
    .b-form .b-agree__text {
      cursor: pointer; }

.b-socials__item {
  display: inline-block;
  vertical-align: top;
  margin-right: 0; }
  .b-socials__item.odnoklassniki {
    margin-right: 0; }
  .b-socials__item.fb {
    margin-right: 9px; }
  .b-socials__item.vk {
    margin-right: 13px; }

.b-socials__img-wrap {
  width: 28px;
  height: 16px; }

.b-socials__image {
  transition: all .3s ease; }
  .b-socials__image:hover {
    filter: drop-shadow(0 0 8px rgba(0, 3, 1, 0.2)); }

.get-size {
  cursor: pointer; }

table {
  border: 1px solid #bfc1c2;
  border-collapse: collapse; }
  table tr:nth-child(odd) {
    background: #eee; }
  table thead th {
    background: #fff; }
  table th,
  table td {
    padding: 18px 22px; }
    table th:first-child,
    table td:first-child {
      border-right: 1px solid #dedede; }
  table th {
    font-size: 18px;
    font-weight: bold;
    text-align: left; }
  table td {
    min-width: 186px;
    color: #000; }

.checkbox {
  display: none; }

/* Задаем внешний вид для нашего кастомного чекбокса. Все обязательные свойства прокомментированы, остальные же свойства меняйте по вашему усмотрению */
.checkbox-custom {
  position: relative;
  margin-right: 6px;
  width: 22px;
  /* Обязательно задаем ширину */
  height: 23px;
  /* Обязательно задаем высоту */
  background: #fff;
  border: 1px solid #d0d0d0; }

/* Кастомный чекбокс и лейбл центрируем по вертикали. Если вам это не требуется, то вы можете убрать свойство vertical-align: middle из данного правила, но свойство display: inline-block обязательно должно быть */
.checkbox-custom,
.label {
  display: inline-block;
  vertical-align: middle; }
  @media screen and (max-width: 460px) {
    .checkbox-custom,
    .label {
      max-width: 250px; } }
  .checkbox-custom:hover,
  .label:hover {
    cursor: pointer; }

/* Если реальный чекбокс у нас отмечен, то тогда добавляем данный признак и к нашему кастомному чекбоксу  */
.checkbox:checked + .checkbox-custom::before {
  content: "";
  /* Добавляем наш псевдоэлемент */
  display: block;
  /* Делаем его блочным элементом */
  position: absolute;
  /* Позиционируем его абсолютным образом */
  /* Задаем расстояние от верхней, правой, нижней и левой границы */
  top: 2px;
  right: 2px;
  bottom: 2px;
  left: 2px;
  background: url("../i/svg/check-green.svg") no-repeat center;
  /* Добавляем фон. Если требуется, можете поставить сюда картинку в виде "галочки", которая будет символизировать, что чекбокс отмечен */
  border-radius: 2px;
  box-shadow: 0 0 8px 1px rgba(0, 3, 1, 0.2); }

/* checkobox
====================================================*/
.windows-checkbox .checkbox {
  display: none; }

.windows-checkbox .checkbox-custom {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-right: 8px;
  width: 23px;
  height: 22px;
  border: 1px solid #d0d0d0;
  background-color: #ffffff;
  border-radius: 50%; }
  .windows-checkbox .checkbox-custom:hover {
    cursor: pointer; }

.windows-checkbox .label {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 14px;
  font-size: 14px;
  line-height: 20px; }

.windows-checkbox .checkbox:checked + .checkbox-custom::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0ebb93 url("../i/svg/check-white.svg") no-repeat center;
  border-radius: 2px; }

.windows-checkbox .checkbox-custom,
.windows-checkbox .checkbox:checked + .checkbox-custom::before {
  border-radius: 50%; }

/* windows-checkbox
====================================================*/
.b-custom-menu {
  /*b-custom-menu__item
  =======================================*/
  /*b-custom-menu__link
  =======================================*/ }
  .b-custom-menu__item {
    font-size: 24px;
    font-weight: bold;
    color: #dd6745;
    text-decoration: underline;
    /*b-custom-menu__item_active
    =======================================*/ }
    .b-custom-menu__item:hover {
      text-decoration: none;
      cursor: pointer; }
    .b-custom-menu__item.active {
      color: #000000;
      text-decoration: none; }
  .b-custom-menu__link {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    text-decoration: inherit; }

input[type="submit"] {
  cursor: pointer; }

blockquote {
  margin: 0 0 30px;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-style: italic; }

strong {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-style: normal; }

a {
  text-decoration: underline;
  color: #dd6745; }
  a:hover {
    text-decoration: none; }
  a:active {
    text-decoration: none;
    color: #000000; }

a.link {
  font-size: 13px;
  text-decoration: underline;
  color: #dd6745; }
  a.link:hover {
    text-decoration: none; }

ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
  /* Убираем исходные маркеры */
  counter-reset: num;
  /* Задаём имя счетчика */ }

.b-unordered li {
  position: relative;
  padding-left: 20px;
  line-height: 26px; }
  .b-unordered li::before {
    content: '';
    position: absolute;
    top: 4px;
    left: 0;
    width: 11px;
    height: 18px;
    background: url("../i/svg/list-icon.svg") no-repeat center; }

.ordered li::before {
  content: counter(num) ". ";
  /* Выводим число */
  counter-increment: num;
  /* Увеличиваем значение счётчика */
  font-weight: bold; }

.block {
  margin-bottom: 40px; }
  .block__item {
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
    width: 40%;
    line-height: 26px; }
  .block.fonts p:first-child {
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    font-style: normal; }
  .block.fonts p:nth-child(2) {
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    font-style: italic; }
  .block.fonts p:last-child {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-style: normal; }
  .block ul.colors li {
    display: inline-block;
    vertical-align: top;
    padding: 19px 45px; }
    .block ul.colors li:first-child {
      background: #dd6745; }
    .block ul.colors li:nth-child(2) {
      background: #0ebb93; }
    .block ul.colors li:last-child {
      background: #efefef; }

.b-btn {
  padding: 16px 58px;
  font-size: 14px;
  color: #fff;
  background: #dd6745;
  border-radius: 23px;
  border: 1px solid #dd6745;
  text-decoration: none;
  transition: all .3s ease; }
  .b-btn:hover {
    color: inherit;
    background: none;
    cursor: pointer; }

.b-arrows {
  margin-bottom: 18px;
  text-align: right;
  /* arrows__item
  ====================================================*/ }
  .b-arrows__item {
    display: inline-block;
    vertical-align: top;
    width: 40px;
    height: 40px;
    line-height: 40px;
    transition: all .3s ease; }
    .b-arrows__item .st0 {
      stroke: #0ebb93; }
    .b-arrows__item svg .st0,
    .b-arrows__item svg .st1 {
      transition: all .3s ease; }
    .b-arrows__item:hover, .b-arrows__item:focus {
      cursor: pointer; }
    .b-arrows__item:hover svg .st0,
    .b-arrows__item:focus svg .st0 {
      fill: transparent; }
    .b-arrows__item:hover svg .st1,
    .b-arrows__item:focus svg .st1 {
      fill: transparent;
      stroke: #0ebb93; }
    .b-arrows__item:first-child {
      margin-right: 20px; }

/* arrows
====================================================*/
.jq-selectbox {
  width: 350px;
  border-radius: 0; }
  @media screen and (max-width: 460px) {
    .jq-selectbox {
      width: 300px; } }
  .jq-selectbox__trigger-arrow {
    top: 7px;
    width: 20px;
    height: 20px;
    font-size: 30px;
    border: none; }
    .jq-selectbox__trigger-arrow::before {
      content: '\f107';
      font-family: 'FontAwesome'; }
  .jq-selectbox.opened .jq-selectbox-trigger-arrow::before {
    content: '\f106';
    font-family: 'FontAwesome'; }
  .jq-selectbox li {
    padding: 15px 20px;
    height: 50px;
    border-radius: 0; }
    .jq-selectbox li:hover {
      color: #fff;
      background: #0ebb93; }
    .jq-selectbox li.selected {
      color: #fff;
      background: #0ebb93; }

.jq-selectbox__select {
  padding: 8px 19px;
  height: 50px;
  background: #fff; }
  .jq-selectbox__select:hover {
    background: none; }

.jq-selectbox__trigger {
  border: none; }

/* form style
====================================================*/
/* общие стили
====================================================*/
/* главная
====================================================*/
.b-header-main {
  transition: all .3s ease;
  background: #fff;
  z-index: 99999; }
  .b-header-main.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 1;
    -o-transform: translate3d(0, 0, 0); }
  .b-header-main__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 100px;
    width: 100%; }
  .b-header-main .b-logo {
    width: 150px;
    height: 30px;
    overflow: hidden; }
  .b-header-main .b-menu-button__open, .b-header-main .b-menu-button__close {
    border: none;
    background: none; }
  @media screen and (max-width: 1030px) {
    .b-header-main .b-menu {
      display: none; } }
  .b-header-main .b-menu__item {
    display: inline-block;
    vertical-align: top;
    margin-right: 24px;
    color: inherit;
    text-decoration: none;
    transition: all .3s ease; }
    .b-header-main .b-menu__item:hover {
      color: #dd6745; }
    .b-header-main .b-menu__item.active {
      font-weight: bold; }
    .b-header-main .b-menu__item:first-child {
      margin-left: 0; }
  .b-header-main .b-menu-media {
    display: none; }
    @media screen and (max-width: 1030px) {
      .b-header-main .b-menu-media {
        padding-left: 40px; } }
    .b-header-main .b-menu-media .b-menu__item {
      display: block;
      margin-bottom: 30px;
      padding-left: 12px;
      font-weight: bold;
      border: none;
      background: none; }

/* header-main
====================================================*/
@media screen and (max-width: 630px) {
  .b-calculate-buttons {
    display: flex;
    justify-content: space-around;
    flex-grow: 1; } }

.b-calculate-buttons__item {
  display: inline-block;
  vertical-align: top;
  width: 192px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  text-decoration: none; }
  @media screen and (max-width: 1290px) {
    .b-calculate-buttons__item {
      text-align: center; } }
  @media screen and (max-width: 1290px) {
    .b-calculate-buttons__item {
      width: 48px; } }
  .b-calculate-buttons__item:hover .b-calculate-buttons__svg svg .st0 {
    stroke: #000;
    opacity: .6; }
  .b-calculate-buttons__item:hover .b-calculate-buttons__text {
    color: #000; }

.b-calculate-buttons__text {
  display: inline-block;
  vertical-align: top;
  line-height: 46px;
  color: #fff; }
  @media screen and (max-width: 1290px) {
    .b-calculate-buttons__text {
      display: none; } }

.b-calculate-buttons__svg {
  position: relative;
  top: 6px;
  right: 0;
  display: inline-block;
  vertical-align: top;
  margin-right: 4px;
  width: 25px;
  height: 25px;
  line-height: 48px;
  transition: all .3s ease; }
  @media screen and (max-width: 1290px) {
    .b-calculate-buttons__svg {
      position: static;
      margin-right: 0;
      line-height: 61px;
      width: 24px; } }
  .b-calculate-buttons__svg svg .st0 {
    fill: none;
    stroke: #fff;
    opacity: .8; }

.b-calculate-buttons .b-btn {
  padding: 0; }

.b-calculate-buttons img {
  position: relative;
  top: 11px;
  right: 6px;
  display: inline-block;
  vertical-align: top;
  width: 25px;
  height: 25px; }

.b-menu {
  background: #fff; }

.b-menu-button {
  display: none; }
  @media screen and (max-width: 1030px) {
    .b-menu-button {
      display: block; } }
  .b-menu-button__close {
    display: none; }

/* header-main
====================================================*/
.b-time {
  font-size: 13px;
  text-align: right; }
  @media screen and (max-width: 630px) {
    .b-time {
      display: none; } }
  .b-time__strong {
    display: block;
    font-size: 18px;
    font-weight: bold; }

.b-phone {
  text-align: right; }
  @media screen and (max-width: 630px) {
    .b-phone {
      display: none; } }
  .b-phone__strong {
    display: block;
    font-size: 18px;
    font-weight: bold; }
  .b-phone__link {
    font-size: 13px;
    text-decoration: underline;
    color: #dd6745;
    background: transparent;
    border: none;
    outline: none; }
    .b-phone__link:hover {
      text-decoration: none; }

/* b-phone
====================================================*/
.b-categories-wrap {
  position: relative;
  min-height: 52px;
  background: #0ebb93;
  transition: opacity .3s ease;
  /* categories
  ====================================================*/
  /* b-categories-wrap.fixed
  ====================================================*/ }
  @media screen and (max-width: 1030px) {
    .b-categories-wrap {
      display: none;
      padding-left: 40px;
      min-height: auto;
      background: #fff; } }
  .b-categories-wrap .b-categories {
    display: inline-block;
    vertical-align: middle;
    /* categories__item
    ====================================================*/
    /* categories__link
    ====================================================*/ }
    @media screen and (max-width: 1030px) {
      .b-categories-wrap .b-categories {
        display: block; } }
    .b-categories-wrap .b-categories__container {
      margin: 0 auto;
      max-width: 1440px;
      text-align: center; }
      .b-categories-wrap .b-categories__container .b-calculate-buttons {
        display: none; }
        @media screen and (max-width: 1400px) {
          .b-categories-wrap .b-categories__container .b-calculate-buttons__item {
            width: 48px; } }
        @media screen and (max-width: 1400px) {
          .b-categories-wrap .b-categories__container .b-calculate-buttons__text {
            display: none; } }
    .b-categories-wrap .b-categories__item {
      display: inline-block;
      vertical-align: top; }
      @media screen and (max-width: 1030px) {
        .b-categories-wrap .b-categories__item {
          display: block;
          padding-left: 34px;
          text-align: left; } }
      .b-categories-wrap .b-categories__item:first-child a {
        padding-left: 0; }
        @media screen and (max-width: 1030px) {
          .b-categories-wrap .b-categories__item:first-child a {
            padding-left: 20px; } }
      .b-categories-wrap .b-categories__item:last-child a {
        padding-right: 40px; }
    .b-categories-wrap .b-categories__link {
      display: block;
      padding: 0 20px;
      font-size: 14px;
      line-height: 52px;
      text-decoration: none;
      color: #fff; }
      @media screen and (max-width: 1030px) {
        .b-categories-wrap .b-categories__link {
          color: #000000; } }
  .b-categories-wrap .b-search {
    display: inline-block;
    line-height: 52px; }
    .b-categories-wrap .b-search__btn {
      position: relative;
      top: 6px;
      border: none;
      background: transparent; }
  .b-categories-wrap .b-search-bar-wrap {
    position: absolute;
    top: 0;
    left: 0;
    padding: 9px 0;
    opacity: 0;
    width: 0;
    min-height: 52px;
    background: #0ebb93;
    transition: opacity .4s ease, width 1s ease;
    z-index: 1; }
    .b-categories-wrap .b-search-bar-wrap.show {
      opacity: 1;
      width: 100%; }
    @media screen and (max-width: 1030px) {
      .b-categories-wrap .b-search-bar-wrap {
        display: block;
        position: static;
        padding: 0 20px;
        min-height: 28px;
        background: #fff; } }
    .b-categories-wrap .b-search-bar-wrap .b-search-bar {
      position: relative;
      margin: 0 auto;
      max-width: 1216px;
      height: 100%;
      border-bottom: 1px solid #fff;
      box-sizing: border-box; }
      @media screen and (max-width: 1030px) {
        .b-categories-wrap .b-search-bar-wrap .b-search-bar {
          border-bottom-color: #000; } }
    .b-categories-wrap .b-search-bar-wrap input {
      width: 100%;
      height: 32px;
      font-size: 16px;
      text-indent: 20px;
      color: #fff;
      background: #0ebb93;
      border: none; }
      @media screen and (max-width: 1030px) {
        .b-categories-wrap .b-search-bar-wrap input {
          color: #000000;
          background: #fff; } }
    .b-categories-wrap .b-search-bar-wrap input::-webkit-input-placeholder {
      color: #fff; }
      @media screen and (max-width: 1030px) {
        .b-categories-wrap .b-search-bar-wrap input::-webkit-input-placeholder {
          color: #000000; } }
    .b-categories-wrap .b-search-bar-wrap input:-moz-placeholder {
      color: #fff; }
      @media screen and (max-width: 1030px) {
        .b-categories-wrap .b-search-bar-wrap input:-moz-placeholder {
          color: #000000; } }
    .b-categories-wrap .b-search-bar-wrap .icon {
      position: absolute;
      top: 0;
      font-size: 30px;
      font-weight: normal;
      color: #fff;
      border: none;
      background: none; }
    .b-categories-wrap .b-search-bar-wrap .icon__search {
      right: 50px; }
    .b-categories-wrap .b-search-bar-wrap .icon__close {
      right: 10px; }
  .b-categories-wrap.fixed {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    opacity: 1;
    min-height: 70px;
    align-items: center;
    z-index: 999;
    /* b-categories__container
    ====================================================*/ }
    .b-categories-wrap.fixed .b-categories {
      display: inline-block;
      vertical-align: middle;
      height: 70px; }
      .b-categories-wrap.fixed .b-categories__item:last-child .b-categories__link {
        padding-right: 28px; }
      .b-categories-wrap.fixed .b-categories__link {
        display: block;
        height: 70px;
        line-height: 70px;
        padding: 0 14px; }
    .b-categories-wrap.fixed .b-search {
      display: inline-block;
      vertical-align: middle;
      margin-right: 47px;
      height: 70px; }
      @media screen and (max-width: 1400px) {
        .b-categories-wrap.fixed .b-search-bar-wrap {
          top: 9px; } }
      .b-categories-wrap.fixed .b-search .b-search__link {
        display: inline-block;
        vertical-align: middle;
        height: 70px;
        line-height: 70px; }
    .b-categories-wrap.fixed .b-calculate-buttons {
      position: relative;
      top: 10px;
      display: inline-block;
      vertical-align: middle;
      height: 70px;
      line-height: 70px;
      /* b-calculate-buttons__item
      ====================================================*/
      /* .b-calculate-buttons__item
      ====================================================*/ }
      .b-categories-wrap.fixed .b-calculate-buttons__item {
        margin-right: 6px; }
        .b-categories-wrap.fixed .b-calculate-buttons__item:last-child {
          margin-right: 0; }
      @media screen and (max-width: 1400px) {
        .b-categories-wrap.fixed .b-calculate-buttons__text {
          display: none; } }

/* categories-wrap
====================================================*/
.b-main-promo {
  position: relative;
  max-width: 2560px;
  min-height: 630px;
  margin: 0 auto;
  background-image: url("../i/main/banner-2560.jpg");
  background-position: center;
  background-repeat: no-repeat;
  /*@media screen and (min-width: 1920px){
    background-image: url("../i/main/banner-2560.jpg");
  }*/ }
  @media screen and (max-width: 660px) {
    .b-main-promo {
      background-image: url("../i/main/banner-660.jpg"); } }
  @media screen and (min-width: 660px) and (max-width: 1600px) {
    .b-main-promo {
      background-image: url("../i/main/banner-1600.jpg"); } }
  @media screen and (min-width: 1600px) and (max-width: 1920px) {
    .b-main-promo {
      background-image: url("../i/main/banner-1920.jpg"); } }
  .b-main-promo .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    @media screen and (max-width: 660px) {
      .b-main-promo .container {
        top: 20%; } }
  .b-main-promo__content {
    max-width: 637px; }
    @media screen and (max-width: 1300px) {
      .b-main-promo__content {
        max-width: 537px; } }
    @media screen and (max-width: 920px) {
      .b-main-promo__content {
        max-width: 400px; } }
    @media screen and (max-width: 660px) {
      .b-main-promo__content {
        max-width: 100%;
        text-align: center; } }
    @media screen and (max-width: 400px) {
      .b-main-promo__content {
        margin-top: 30px; } }
  .b-main-promo__title {
    margin-bottom: 0;
    font-size: 52px;
    line-height: 56px;
    text-transform: uppercase; }
    @media screen and (max-width: 1300px) {
      .b-main-promo__title {
        font-size: 44px;
        line-height: 50px; } }
    @media screen and (max-width: 1120px) {
      .b-main-promo__title {
        font-size: 34px;
        line-height: 42px; } }
    @media screen and (max-width: 920px) {
      .b-main-promo__title {
        font-size: 24px;
        line-height: 30px; } }
  .b-main-promo__description {
    margin-bottom: 56px;
    font-size: 30px; }
    @media screen and (max-width: 920px) {
      .b-main-promo__description {
        font-size: 20px; } }
  @media screen and (max-width: 660px) {
    .b-main-promo .b-btn {
      display: none; } }

/* main-promo
====================================================*/
.b-features {
  padding: 90px 0 100px 0;
  background: #efefef;
  /* b-features__header
  ====================================================*/
  /* b-features__content
  ====================================================*/
  /*b-features__item
  =======================================*/ }
  .b-features__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    /* b-features__header_content
    ====================================================*/
    /*b-features__header_title
    =======================================*/
    /* b-features__header_title
    =======================================*/ }
    @media screen and (max-width: 940px) {
      .b-features__header {
        display: block; } }
    .b-features__header_content {
      width: 724px; }
      @media screen and (max-width: 940px) {
        .b-features__header_content {
          margin-bottom: 80px;
          width: auto; } }
    .b-features__header .img-wrap {
      width: 461px; }
      @media screen and (max-width: 940px) {
        .b-features__header .img-wrap {
          width: auto; } }
    .b-features__header_title {
      margin-bottom: 30px; }
    .b-features__header_text {
      margin-bottom: 35px;
      font-size: 16px; }
  .b-features .b-arrows {
    display: none; }
    @media screen and (max-width: 480px) {
      .b-features .b-arrows {
        display: block; } }
  .b-features__content {
    box-shadow: 0 0 19px 1px rgba(92, 92, 92, 0.2);
    background-color: #ffffff; }
    .b-features__content .st0,
    .b-features__content .st1 {
      transition: all .2s ease; }
    .b-features__content .st0 {
      fill: none;
      stroke: #0EBB93; }
    .b-features__content .st1 {
      fill: none;
      stroke: #F14F20; }
    .b-features__content .img-wrap {
      margin-bottom: 15px;
      width: 54px;
      height: 54px; }
  .b-features__item {
    padding: 40px 40px 60px 30px;
    border-right: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    transition: all .3s ease; }
    .b-features__item:hover {
      color: #fff;
      background: #0ebb93; }
    .b-features__item:hover .b-features__item_title {
      color: #fff; }
    .b-features__item:hover .st0,
    .b-features__item:hover .st1 {
      stroke: #fff; }
    .b-features__item_title {
      margin-bottom: 4px;
      min-height: 64px;
      line-height: 1;
      font-size: 24px;
      color: #0ebb93;
      overflow: hidden; }
      @media screen and (max-width: 767px) {
        .b-features__item_title {
          min-height: 34px; } }
    .b-features__item_text {
      height: 110px;
      overflow: hidden; }
      @media screen and (max-width: 767px) {
        .b-features__item_text {
          min-height: 34px;
          height: auto; } }
  @media screen and (max-width: 480px) {
    .b-features .slick-slide {
      padding: 0; } }

/* b_features
====================================================*/
/* free-measure
====================================================*/
.b-free-measure {
  padding: 84px 0 78px;
  min-height: 402px;
  background: url("../i/tape.svg") repeat-x top center;
  background-size: cover; }
  .b-free-measure__title {
    margin-bottom: 26px; }
  .b-free-measure__content {
    position: relative;
    margin-left: 165px; }
    @media screen and (max-width: 1355px) {
      .b-free-measure__content {
        margin-left: 0; } }
    @media screen and (max-width: 1180px) {
      .b-free-measure__content {
        display: table;
        margin: 0 auto; } }
  .b-free-measure .b-ruler {
    position: absolute;
    top: -96px;
    right: -10px; }
    @media screen and (max-width: 1180px) {
      .b-free-measure .b-ruler {
        display: none; } }

/* free-measure
====================================================*/
.b-products {
  padding: 80px 0; }
  .b-products h2 {
    margin-bottom: 44px;
    font-size: 46px; }
  .b-products__item {
    margin-bottom: 30px;
    text-align: center; }
    .b-products__item .img-wrap {
      position: relative;
      margin: 0 auto 20px;
      width: 222px;
      height: 222px;
      border-radius: 50%;
      overflow: hidden; }
    .b-products__item img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 180%; }
    .b-products__item h3 {
      margin-bottom: 0;
      color: #0ebb93; }
    .b-products__item p {
      margin: 0;
      font-size: 22px; }

/* index-tabs
====================================================*/
.b-tabs {
  margin-bottom: 110px;
  /* b-tabs__list
  ====================================================*/
  /*b-tabs__content
  =======================================*/ }
  .b-tabs__list {
    text-align: center;
    /*b-tabs__list__item
    =======================================*/ }
    .b-tabs__list__item {
      display: inline-block;
      vertical-align: top;
      margin-right: 30px;
      margin-bottom: 25px; }
      @media screen and (max-width: 940px) {
        .b-tabs__list__item {
          display: block; } }
  .b-tabs__content {
    /*b-tabs__content__img-wrap
    =======================================*/
    /*b-tabs__content__item
    =======================================*/
    /*button.b-btn
    =======================================*/
    /* b-tabs-content__subitem
    ====================================================*/
    /*b-tabs__content__image
    =======================================*/
    /*b-tabs__content__data
    =======================================*/ }
    .b-tabs__content__img-wrap {
      margin: 0 auto 24px;
      width: 82px;
      height: 82px;
      overflow: hidden; }
    .b-tabs__content__item .img-wrap {
      margin: 0 auto 24px;
      width: 82px;
      height: 82px;
      overflow: hidden; }
    .b-tabs__content .b-btn-tabs {
      display: none;
      justify-content: center;
      align-items: center;
      margin: 0 auto 42px;
      padding: 0;
      width: 297px;
      height: 50px;
      font-size: 14px;
      color: #000000;
      background: transparent; }
      @media screen and (max-width: 1200px) {
        .b-tabs__content .b-btn-tabs {
          display: flex; } }
      .b-tabs__content .b-btn-tabs:hover {
        color: #fff;
        background: #dd6745; }
      .b-tabs__content .b-btn-tabs__img-wrap {
        margin: 0;
        margin-right: 14px;
        height: 25px; }
    .b-tabs__content__subitem {
      display: flex;
      margin-bottom: 30px;
      padding: 40px;
      border: 1px solid #dcdcdc;
      border-radius: 10px; }
      @media screen and (max-width: 1200px) {
        .b-tabs__content__subitem {
          margin: 0 auto 30px;
          max-width: 700px; } }
      @media screen and (max-width: 540px) {
        .b-tabs__content__subitem {
          display: block; } }
    .b-tabs__content__image {
      width: 172px;
      margin-right: 23px;
      /*b-tabs__content__image__title
      =======================================*/
      /*b-tabs__content__image__text
      =======================================*/ }
      @media screen and (max-width: 540px) {
        .b-tabs__content__image__title {
          margin-bottom: 30px; } }
      .b-tabs__content__image__text {
        display: block;
        text-align: center;
        font-size: 18px;
        line-height: 20px; }
    .b-tabs__content__data {
      /*b-tabs-content__data__title
      =======================================*/
      /*b-tabs-content__data__price
      =======================================*/
      /*b-tabs__content__data__no-price
      =======================================*/ }
      .b-tabs__content__data__title {
        margin-bottom: 20px;
        font-size: 14px;
        font-weight: bold;
        line-height: 20px; }
      .b-tabs__content__data .b-unordered {
        margin-bottom: 66px;
        height: 130px;
        overflow: hidden; }
        @media screen and (max-width: 1200px) {
          .b-tabs__content__data .b-unordered {
            height: auto; } }
        @media screen and (max-width: 540px) {
          .b-tabs__content__data .b-unordered {
            margin-bottom: 16px; } }
      .b-tabs__content__data__price {
        margin-bottom: 22px;
        margin-right: 15px;
        font-size: 24px;
        font-weight: bold; }
      .b-tabs__content__data__no-price {
        font-size: 18px;
        font-weight: normal;
        text-decoration: line-through;
        color: #c2c2c2; }

/*b-tabs
=======================================*/
.b-choice {
  position: relative;
  padding: 50px 0 30px;
  background: url("../i/choice-bg.jpg") no-repeat center;
  box-shadow: 0 0 10px 1px rgba(92, 92, 92, 0.3);
  border-radius: 10px;
  background-size: cover;
  z-index: 10;
  /*b-choice__content
  =======================================*/ }
  .b-choice__title {
    margin-bottom: 20px;
    font-size: 32px;
    line-height: 1.2;
    color: #fff; }
    @media screen and (max-width: 340px) {
      .b-choice__title {
        font-size: 24px; } }
  @media screen and (max-width: 1180px) {
    .b-choice__text {
      max-width: 400px; } }
  .b-choice .b-form-group__input {
    position: relative; }
  .b-choice__content {
    position: relative;
    display: inline-block;
    vertical-align: top;
    padding-left: 350px;
    margin-right: 76px;
    width: 578px; }
    @media screen and (max-width: 1220px) {
      .b-choice__content {
        margin-right: 44px; } }
    @media screen and (max-width: 1190px) {
      .b-choice__content {
        padding-left: 40px; } }
    @media screen and (max-width: 1180px) {
      .b-choice__content {
        display: table;
        margin: 0 auto 16px;
        padding-left: 0;
        width: auto;
        text-align: center; } }
    @media screen and (max-width: 580px) {
      .b-choice__content {
        padding-left: 0;
        text-align: center; } }
    .b-choice__content::before {
      content: '';
      position: absolute;
      top: -117px;
      left: 88px;
      width: 284px;
      height: 338px;
      background: url("../i/choice-img.png") no-repeat center;
      background-size: cover; }
      @media screen and (max-width: 1190px) {
        .b-choice__content::before {
          display: none; } }
  .b-choice .b-form {
    display: inline-block;
    vertical-align: top; }
    @media screen and (max-width: 1180px) {
      .b-choice .b-form {
        display: table;
        margin: 0 auto;
        width: auto; } }
    @media screen and (max-width: 580px) {
      .b-choice .b-form {
        text-align: center; } }
    .b-choice .b-form-group__label {
      text-align: left; }

/* b-choice
====================================================*/
.b-reviews {
  /* b-reviews__wrap
  ====================================================*/
  /* b-reviews__item
  ====================================================*/ }
  .b-reviews-wrap {
    position: relative;
    margin-top: -138px;
    padding-top: 224px;
    padding-bottom: 40px;
    background: #efefef;
    z-index: 8; }
    .b-reviews-wrap .container {
      position: relative; }
  @media screen and (max-width: 600px) {
    .b-reviews__title {
      margin-bottom: 20px; } }
  @media screen and (max-width: 600px) {
    .b-reviews__title .hidden {
      display: none; } }
  .b-reviews .slick-list {
    margin: 0 -67px; }
    @media screen and (max-width: 1500px) {
      .b-reviews .slick-list {
        margin: 0; } }
  .b-reviews__item {
    margin: 0 67px;
    padding: 40px 40px 60px;
    box-shadow: 0px 0px 19.2px 0.8px rgba(92, 92, 92, 0.2);
    border-radius: 10px;
    background-color: #ffffff;
    /*b-reviews__title
    =======================================*/
    /*b-reviews__item__text
    =======================================*/
    /* b-reviews__item-header
    ====================================================*/
    /*b-reviews__item_img-wrap
    =======================================*/
    /*b-reviews__item__title
    =======================================*/ }
    @media screen and (max-width: 1500px) {
      .b-reviews__item {
        margin: 0 5px; } }
    .b-reviews__item__title {
      margin-bottom: 38px;
      font-weight: bold; }
    .b-reviews__item__text {
      min-height: 154px;
      overflow: hidden; }
    .b-reviews__item__header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start; }
    .b-reviews__item__img-wrap {
      width: 72px;
      height: 55px; }
    .b-reviews__item__title {
      /*b-reviews__item__title__part
      =======================================*/ }
      .b-reviews__item__title__part {
        display: block; }

/* reviews
====================================================*/
.b-clients {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /*b-clients__item
  =======================================*/ }
  @media screen and (max-width: 500px) {
    .b-clients {
      display: block;
      padding: 20px 0; } }
  @media screen and (max-width: 500px) {
    .b-clients::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 30%;
      height: 100%;
      background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.4)); } }
  .b-clients-wrap {
    padding: 40px 0 60px;
    background: #efefef; }
  .b-clients__item {
    margin-right: 30px; }
    @media screen and (max-width: 500px) {
      .b-clients__item {
        margin-right: 0;
        text-align: center; } }
  .b-clients__link {
    display: inline-block;
    vertical-align: middle;
    line-height: 108px; }

/* clients
====================================================*/
.b-scheme {
  /*b-scheme__wrap
  =======================================*/
  /*b-scheme__title
  =======================================*/
  /*b-scheme__img-wrap
  =======================================*/
  /*b-scheme__item
  =======================================*/ }
  .b-scheme-wrap {
    padding: 65px 0 40px; }
  .b-scheme__title {
    margin-bottom: 77px; }
  .b-scheme__item {
    /*b-scheme__title
    =======================================*/ }
    @media screen and (max-width: 1200px) {
      .b-scheme__item {
        margin-bottom: 40px; } }
    @media screen and (max-width: 576px) {
      .b-scheme__item {
        text-align: center; } }
    .b-scheme__item__title {
      margin-bottom: 12px;
      height: 40px;
      line-height: 22px;
      font-size: 18px;
      font-weight: bold;
      color: #0ebb93; }
      @media screen and (max-width: 992px) {
        .b-scheme__item__title {
          height: auto; } }
  .b-scheme__img-wrap {
    margin-bottom: 23px;
    width: 47px;
    height: 42px; }
    @media screen and (max-width: 576px) {
      .b-scheme__img-wrap {
        display: inline-block; } }

/* b-scheme
====================================================*/
.b-our-projects-wrap .container-main {
  position: relative; }

.b-our-projects {
  position: relative;
  /* .b-our-projects__item
  ====================================================*/
  /* b-our-projects-wrap
  ====================================================*/
  /* slick-slide
  ====================================================*/ }
  .b-our-projects__title {
    margin-bottom: 84px; }
    @media screen and (max-width: 660px) {
      .b-our-projects__title {
        margin-bottom: 35px; } }
  .b-our-projects__img-wrap {
    position: relative;
    cursor: pointer; }
    .b-our-projects__img-wrap::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
      transition: all .3s ease; }
      @media screen and (max-width: 992px) {
        .b-our-projects__img-wrap::after {
          display: none; } }
  .b-our-projects-wrap .container-main {
    position: relative; }
  .b-our-projects-wrap .b-arrows {
    position: absolute;
    top: 45%;
    left: 0;
    display: block;
    width: 100%;
    height: 40px; }
    @media screen and (max-width: 576px) {
      .b-our-projects-wrap .b-arrows {
        display: none; } }
    .b-our-projects-wrap .b-arrows__item {
      position: absolute;
      top: 0;
      z-index: 999; }
    .b-our-projects-wrap .b-arrows .b-arrow__item_prev {
      left: 30%; }
      @media screen and (max-width: 1200px) {
        .b-our-projects-wrap .b-arrows .b-arrow__item_prev {
          left: 29%; } }
      @media screen and (max-width: 992px) {
        .b-our-projects-wrap .b-arrows .b-arrow__item_prev {
          left: 40px; } }
    .b-our-projects-wrap .b-arrows .b-arrow__item_next {
      right: 30%; }
      @media screen and (max-width: 1200px) {
        .b-our-projects-wrap .b-arrows .b-arrow__item_next {
          right: 29%; } }
      @media screen and (max-width: 992px) {
        .b-our-projects-wrap .b-arrows .b-arrow__item_next {
          right: 40px; } }
    .b-our-projects-wrap .b-arrows .b-arrow svg {
      outline: 1px solid red; }
  .b-our-projects .slick-slide {
    position: relative;
    margin: 0;
    width: 100%;
    transform: scale(0.7);
    border: 4px solid transparent;
    z-index: 1000; }
    @media screen and (max-width: 992px) {
      .b-our-projects .slick-slide {
        transform: scale(1);
        border: none; } }
    .b-our-projects .slick-slide.slick-current {
      transform: scale(1);
      border-color: #0ebb93; }
      @media screen and (max-width: 992px) {
        .b-our-projects .slick-slide.slick-current {
          border: none; } }
      .b-our-projects .slick-slide.slick-current:hover {
        cursor: pointer; }
      .b-our-projects .slick-slide.slick-current .b-our-projects__img-wrap {
        position: relative;
        /* .b-our-projects__img-wrap::after
        ====================================================*/
        /* .b-our-projects__img-wrap::before
        ====================================================*/
        /* .b-our-projects__img-wrap:hover
        ====================================================*/ }
        .b-our-projects .slick-slide.slick-current .b-our-projects__img-wrap::after {
          background: none;
          z-index: 10; }
        .b-our-projects .slick-slide.slick-current .b-our-projects__img-wrap::before {
          content: '';
          position: absolute;
          top: -50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 31px;
          height: 31px;
          background: url("../i/svg/loupe.svg") no-repeat center;
          opacity: 0;
          transition: all .6s ease;
          z-index: 11; }
        .b-our-projects .slick-slide.slick-current .b-our-projects__img-wrap:hover {
          /* .b-our-projects__img-wrap:hover::after
          ====================================================*/ }
          .b-our-projects .slick-slide.slick-current .b-our-projects__img-wrap:hover::after {
            background: rgba(0, 0, 0, 0.6); }
          .b-our-projects .slick-slide.slick-current .b-our-projects__img-wrap:hover::before {
            top: 52%;
            opacity: 1; }

/* our-projects
====================================================*/
.b-index-contacts {
  padding: 113px 0 70px;
  /*b-index-contacts__wrap
  =======================================*/
  /*b-index-contacts__title
  =======================================*/
  /*b-index-contacts__content
  =======================================*/
  /*b-index-contacts__item
  =======================================*/ }
  .b-index-contacts__wrap {
    display: flex;
    justify-content: space-between; }
    @media screen and (max-width: 992px) {
      .b-index-contacts__wrap {
        display: block; } }
  .b-index-contacts__img-wrap {
    margin-bottom: 10px;
    width: 25px;
    height: 25px; }
  .b-index-contacts__title {
    margin-bottom: 30px;
    margin-left: -10px; }
  .b-index-contacts__content {
    width: 38%;
    vertical-align: top;
    padding-left: 100px; }
    @media screen and (max-width: 1500px) {
      .b-index-contacts__content {
        padding-left: 10px; } }
    @media screen and (max-width: 992px) {
      .b-index-contacts__content {
        width: auto; } }
  .b-index-contacts__item {
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
    margin-bottom: 40px;
    max-width: 276px;
    /*b-index-contacts__item__title
    =======================================*/
    /*b-index-contacts__item__
    =======================================*/ }
    .b-index-contacts__item__title {
      margin-bottom: 18px; }
    .b-index-contacts__item__text {
      font-style: normal;
      font-size: 14px; }
    .b-index-contacts__item__part {
      display: block; }
  .b-index-contacts .map {
    width: 60%;
    height: 304px; }
    @media screen and (max-width: 992px) {
      .b-index-contacts .map {
        width: auto; } }

/* index-contacts
====================================================*/
.b-footer-main {
  padding-bottom: 20px;
  background: #efefef;
  /*b-footer-main__header
  =======================================*/
  /* b-footer-main__content
  ====================================================*/
  /* b-footer-main__footer
  ====================================================*/ }
  .b-footer-main__header {
    padding: 28px 0;
    border-bottom: 1px solid #d0d0d0; }
    @media screen and (max-width: 440px) {
      .b-footer-main__header {
        display: none; } }
  .b-footer-main .b-footer-menu {
    text-align: center;
    /*b-footer-menu__link
    =======================================*/ }
    .b-footer-main .b-footer-menu__link {
      margin-right: 55px;
      font-weight: bold;
      text-decoration: none;
      color: inherit; }
  .b-footer-main__content {
    display: flex;
    justify-content: space-between;
    padding: 22px 0 38px; }
    @media screen and (max-width: 800px) {
      .b-footer-main__content {
        display: block; } }
    .b-footer-main__content span {
      display: block;
      margin-bottom: 3px; }
  @media screen and (max-width: 800px) {
    .b-footer-main__item {
      display: inline-block;
      vertical-align: top;
      margin-bottom: 30px;
      width: 40%; } }
  @media screen and (max-width: 400px) {
    .b-footer-main__item {
      display: block;
      width: auto;
      text-align: center; } }
  .b-footer-main__footer {
    /*b-footer-main__footer__text
    =======================================*/ }
    .b-footer-main__footer__text {
      margin: 0 auto;
      max-width: 490px;
      font-size: 10px;
      text-align: center; }

/* b-footer-main index.html
====================================================*/
.main-container .b-big-header {
  display: flex;
  align-items: flex-end;
  min-height: 334px;
  background: url("../i/about-big-header-bg.jpg") no-repeat center;
  background-size: cover; }
  .main-container .b-big-header__title {
    display: block;
    flex-grow: 1; }
  .main-container .b-big-header h2 {
    margin: 0 auto 40px;
    padding: 0 15px;
    max-width: 1400px;
    color: #fff; }

.b-about-main {
  padding: 57px 0 67px;
  /* big-header about.html
====================================================*/
  /* about-content
  ====================================================*/
  /* sidebar
  ====================================================*/
  /* sidebar
  ====================================================*/ }
  .b-about-main .container {
    display: flex;
    justify-content: space-between;
    width: 100%; }
    @media screen and (max-width: 1140px) {
      .b-about-main .container {
        flex-direction: column; } }
  .b-about-main__content {
    max-width: 830px; }
    @media screen and (max-width: 1140px) {
      .b-about-main__content {
        max-width: none;
        order: 2; } }
    .b-about-main__content p {
      margin-bottom: 27px; }
    .b-about-main__content .img-wrap {
      margin-bottom: 37px; }
  .b-about-main__text__title {
    font-size: 24px; }
  .b-about-main .b-blocquote-block {
    margin-bottom: 40px;
    padding: 52px 70px 50px 41px;
    border-radius: 10px;
    border: 4px solid #0ebb93; }
  .b-about-main .b-sidebar {
    width: 355px;
    /* sidebar-menu-bar
    ====================================================*/
    /* sidebar__title
    ====================================================*/ }
    @media screen and (max-width: 1255px) {
      .b-about-main .b-sidebar {
        max-width: 355px;
        width: auto; } }
    @media screen and (max-width: 1140px) {
      .b-about-main .b-sidebar {
        max-width: none;
        order: 1; } }
    .b-about-main .b-sidebar .b-custom-menu {
      /* b-custom-menu__item
      ====================================================*/ }
      @media screen and (max-width: 1140px) {
        .b-about-main .b-sidebar .b-custom-menu {
          display: none; } }
      @media screen and (min-width: 1140px) {
        .b-about-main .b-sidebar .b-custom-menu {
          display: block !important; } }
      .b-about-main .b-sidebar .b-custom-menu__item {
        margin-bottom: 25px; }
    .b-about-main .b-sidebar-menu-bar {
      display: none; }
      @media screen and (max-width: 1140px) {
        .b-about-main .b-sidebar-menu-bar {
          display: flex;
          width: 100%;
          justify-content: flex-start; } }
      @media screen and (max-width: 420px) {
        .b-about-main .b-sidebar-menu-bar {
          justify-content: space-between; } }
      .b-about-main .b-sidebar-menu-bar .fa {
        font-size: 40px; }
    .b-about-main .b-sidebar__title {
      margin-right: 160px; }
      @media screen and (max-width: 420px) {
        .b-about-main .b-sidebar__title {
          margin-right: 0; } }

/* about-main
====================================================*/
.calc-main {
  padding: 63px 0 67px; }
  .calc-main .description {
    margin-bottom: 40px;
    max-width: 720px; }
  .calc-main .window-title {
    margin-bottom: 43px;
    font-size: 24px; }

.windows {
  margin-bottom: 170px;
  width: 100%;
  /* windows__type
  ====================================================*/
  /* windows__preview
  ====================================================*/
  /* windows__options
  ====================================================*/
  /* windows__elements
  ====================================================*/
  /* windows__price
  ====================================================*/ }
  .windows__type {
    display: inline-block;
    vertical-align: top;
    margin-right: 80px;
    width: 150px; }
    @media screen and (max-width: 736px) {
      .windows__type {
        display: block;
        width: auto;
        margin-right: 0; } }
    .windows__type .img-wrap {
      position: relative; }
      @media screen and (max-width: 736px) {
        .windows__type .img-wrap {
          display: inline-block;
          vertical-align: top; } }
    .windows__type img.b-calc__window {
      position: relative;
      display: block;
      width: 100px;
      height: 100px;
      cursor: pointer;
      z-index: 10; }
      @media screen and (max-width: 736px) {
        .windows__type img.b-calc__window {
          display: inline-block;
          vertical-align: top; } }
      @media screen and (max-width: 360px) {
        .windows__type img.b-calc__window {
          width: 56px;
          height: 56px; } }
    .windows__type .b-calc__window-active {
      background: none;
      border: none;
      box-shadow: none; }
      .windows__type .b-calc__window-active + .circle {
        position: absolute;
        top: 4px;
        left: 6px;
        width: 90px;
        height: 90px;
        background: #0ebb93;
        border-radius: 50%;
        z-index: 8; }
        @media screen and (max-width: 360px) {
          .windows__type .b-calc__window-active + .circle {
            top: 3px;
            left: 4px;
            width: 50px;
            height: 50px; } }
  .windows__preview {
    display: inline-block;
    vertical-align: top;
    margin-right: 110px;
    width: 361px; }
    @media screen and (max-width: 460px) {
      .windows__preview {
        margin-right: 0;
        width: 300px; } }
  .windows__options {
    display: inline-block;
    vertical-align: top;
    width: 405px; }
    @media screen and (max-width: 460px) {
      .windows__options {
        width: 300px; } }
    .windows__options label {
      display: block;
      margin-bottom: 9px;
      font-size: 18px;
      font-weight: bold; }
    .windows__options .form-group {
      margin-bottom: 36px; }
  .windows__elements h4 {
    margin-bottom: 22px;
    font-size: 18px; }
  .windows__price {
    display: inline-block;
    vertical-align: top;
    padding: 35px 23px;
    width: 238px;
    text-align: center;
    border-radius: 10px;
    border: 4px solid #0ebb93; }
    .windows__price h4 {
      margin-bottom: 28px;
      font-size: 18px; }
    .windows__price p {
      margin-bottom: 26px;
      font-size: 24px;
      font-weight: bold; }

.template {
  padding: 60px 0; }
  .template .b-form {
    margin-top: 40px; }

.b-modal-tips {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 128px 38px 40px 46px;
  display: none;
  width: 544px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: normal;
  font-style: normal;
  background: url("../i/modal-1-bg.jpg") no-repeat center;
  background-size: cover;
  border-radius: 10px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.4);
  z-index: 999999999;
  transition: top 4s ease; }
  @media screen and (max-width: 570px) {
    .b-modal-tips {
      padding: 90px 13px 70px 13px;
      width: 280px; } }
  .b-modal-tips .close-modal {
    position: absolute;
    top: 15px;
    right: 15px; }
  .b-modal-tips__title {
    font-family: inherit;
    font-size: 66px;
    color: #0ebb93; }
    @media screen and (max-width: 570px) {
      .b-modal-tips__title {
        font-size: 54px; } }
  .b-modal-tips__subtitle {
    font-family: inherit;
    font-size: 24px; }
    @media screen and (max-width: 570px) {
      .b-modal-tips__subtitle {
        font-size: 20px; } }
  .b-modal-tips__checklist {
    font-size: 18px; }
    @media screen and (max-width: 570px) {
      .b-modal-tips__checklist {
        font-size: 14px; } }
  .b-modal-tips .b-form-group {
    display: inline-block;
    vertical-align: top; }
    .b-modal-tips .b-form-group.submit {
      position: relative;
      top: 30px;
      width: auto; }
      @media screen and (max-width: 570px) {
        .b-modal-tips .b-form-group.submit {
          top: 0; } }
    .b-modal-tips .b-form-group input[type="email"] {
      margin-bottom: 17px; }
  .b-modal-tips .b-agree {
    margin-bottom: 0; }
    .b-modal-tips .b-agree__label {
      position: relative;
      top: 3px;
      max-width: 200px;
      font-size: 13px;
      font-weight: normal; }

.b-thanks {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 70px 40px;
  display: none;
  width: 644px;
  text-align: center;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.4);
  z-index: 999999999; }
  @media screen and (max-width: 700px) {
    .b-thanks {
      padding: 100px 18px;
      width: 290px; } }
  .b-thanks .b-social-links {
    margin-bottom: 40px; }
    @media screen and (max-width: 700px) {
      .b-thanks .b-social-links {
        margin-bottom: 90px; } }

.b-modal-request {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 48px 38px 40px 46px;
  display: none;
  width: 644px;
  text-align: center;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.4);
  z-index: 999999999; }
  @media screen and (max-width: 700px) {
    .b-modal-request {
      padding: 80px 15px 30px;
      width: 280px; } }
  .b-modal-request .close-modal {
    position: absolute;
    top: 15px;
    right: 15px; }
  .b-modal-request__title {
    margin-bottom: 20px;
    font-family: inherit;
    line-height: 1.1; }
    @media screen and (max-width: 700px) {
      .b-modal-request__title {
        font-size: 22px; } }
  .b-modal-request__subtitle {
    display: block;
    margin-bottom: 30px; }
    @media screen and (max-width: 700px) {
      .b-modal-request__subtitle {
        margin: 0 auto 30px;
        max-width: 230px; } }
  .b-modal-request__checklist {
    font-size: 18px; }
    @media screen and (max-width: 570px) {
      .b-modal-request__checklist {
        font-size: 14px; } }
  .b-modal-request .b-form-group {
    display: inline-block;
    vertical-align: top; }
    .b-modal-request .b-form-group__inner {
      position: relative; }
    .b-modal-request .b-form-group__label {
      text-align: left; }
    .b-modal-request .b-form-group.submit {
      position: relative;
      top: 30px;
      width: auto; }
      @media screen and (max-width: 570px) {
        .b-modal-request .b-form-group.submit {
          top: 0; } }
    .b-modal-request .b-form-group input[type="email"] {
      margin-bottom: 17px; }
  .b-modal-request .b-agree {
    margin-bottom: 40px;
    margin-left: 16px;
    text-align: left; }
    .b-modal-request .b-agree__label {
      position: relative;
      top: 3px;
      font-size: 13px;
      font-weight: normal; }
      @media screen and (max-width: 700px) {
        .b-modal-request .b-agree__label {
          width: 200px; } }

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999999998; }

/* modals
====================================================*/
.b-form {
  /*b-form-group
  =======================================*/ }
  .b-form-group__inner {
    position: relative; }
  .b-form-group-input {
    position: relative; }
  .b-form-group input[type="text"]:focus,
  .b-form-group input[type="email"]:focus {
    box-shadow: 0 0 8px 1px rgba(0, 3, 1, 0.2); }
  .b-form-group input.error {
    border-color: #ef1b20; }
  .b-form-group label.error {
    position: absolute;
    top: 15px;
    left: 15px;
    font-size: 14px;
    font-weight: normal;
    color: #ef1b20; }
  .b-form__submit {
    font-size: 14px;
    cursor: pointer; }
    .b-form__submit.disabled {
      opacity: .6; }
      .b-form__submit.disabled:hover {
        color: #fff;
        background: #ef1b20;
        opacity: .3; }

/* form
====================================================*/
.cont_select_int .active,
.cont_select_int li:hover {
  background: #0ebb93; }

.cont_select_int li {
  padding: 18px 22px;
  float: none;
  width: 100%;
  border: 1px solid #ccc;
  border-top-color: transparent; }

.select_mate {
  border: 1px solid #ccc;
  box-shadow: none; }
